@import "frappe/public/css/fonts/inter/inter.css";
:root {
  --neutral-white: #ffffff;
  --neutral-black: #000000;
  --neutral: var(--neutral-white);
  --invert-neutral: var(--neutral-black);
  --gray-50: #f8f8f8;
  --gray-100: #f3f3f3;
  --gray-200: #ededed;
  --gray-300: #e2e2e2;
  --gray-400: #c7c7c7;
  --gray-500: #999999;
  --gray-600: #7c7c7c;
  --gray-700: #525252;
  --gray-800: #383838;
  --gray-900: #171717;
  --blue-50: #f7fbfd;
  --blue-100: #edf6fd;
  --blue-200: #e3f1fd;
  --blue-300: #c9e7fc;
  --blue-400: #70b6f0;
  --blue-500: #0289f7;
  --blue-600: #007be0;
  --blue-700: #0070cc;
  --blue-800: #005ca3;
  --blue-900: #004880;
  --green-50: #f3fcf5;
  --green-100: #e4f5e9;
  --green-200: #daf0e1;
  --green-300: #cae5d4;
  --green-400: #b6dec5;
  --green-500: #59ba8b;
  --green-600: #30a66d;
  --green-700: #278f5e;
  --green-800: #16794c;
  --green-900: #173b2c;
  --red-50: #fff7f7;
  --red-100: #fff0f0;
  --red-200: #fcd7d7;
  --red-300: #f9c6c6;
  --red-400: #eb9091;
  --red-500: #e03636;
  --red-600: #cc2929;
  --red-700: #b52a2a;
  --red-800: #941f1f;
  --red-900: #6b1515;
  --orange-50: #fff9f5;
  --orange-100: #fff1e7;
  --orange-200: #fce6d5;
  --orange-300: #f7d6bd;
  --orange-400: #f0b58b;
  --orange-500: #e86c13;
  --orange-600: #d45a08;
  --orange-700: #bd3e0c;
  --orange-800: #9e3513;
  --orange-900: #6b2711;
  --amber-50: #fdfaed;
  --amber-100: #fcf3cf;
  --amber-200: #f7e28d;
  --amber-300: #f5d261;
  --amber-400: #f2be3a;
  --amber-500: #e79913;
  --amber-600: #db7706;
  --amber-700: #b35309;
  --amber-800: #91400d;
  --amber-900: #763813;
  --yellow-50: #fffcef;
  --yellow-100: #fff7d3;
  --yellow-200: #f7e9a8;
  --yellow-300: #f5e171;
  --yellow-400: #f2d14b;
  --yellow-500: #edba13;
  --yellow-600: #d1930d;
  --yellow-700: #ab6e05;
  --yellow-800: #8c5600;
  --yellow-900: #733f12;
  --cyan-50: #f5fbfc;
  --cyan-100: #e0f8ff;
  --cyan-200: #b3ecfc;
  --cyan-300: #94e6ff;
  --cyan-400: #6bd3f2;
  --cyan-500: #34bae3;
  --cyan-600: #32a4c7;
  --cyan-700: #267a94;
  --cyan-800: #125c73;
  --cyan-900: #164759;
  --teal-50: #f0fdfa;
  --teal-100: #e6f7f4;
  --teal-200: #bae8e1;
  --teal-300: #97ded4;
  --teal-400: #73d1c4;
  --teal-500: #36baad;
  --teal-600: #0b9e92;
  --teal-700: #0f736b;
  --teal-800: #115c57;
  --teal-900: #114541;
  --violet-50: #fbfaff;
  --violet-100: #f5f2ff;
  --violet-200: #e5e1fa;
  --violet-300: #dad2f7;
  --violet-400: #bdb1f0;
  --violet-500: #6846e3;
  --violet-600: #5f46c7;
  --violet-700: #4f3da1;
  --violet-800: #392980;
  --violet-900: #251959;
  --pink-50: #fff7fc;
  --pink-100: #feeef8;
  --pink-200: #f8e2f0;
  --pink-300: #f2d4e6;
  --pink-400: #e9c4da;
  --pink-500: #e34aa6;
  --pink-600: #cf3a96;
  --pink-700: #9c2671;
  --pink-800: #801458;
  --pink-900: #570f3e;
  --purple-50: #fdfaff;
  --purple-100: #f9f0ff;
  --purple-200: #f1e5fa;
  --purple-300: #e9d6f5;
  --purple-400: #d6c1e6;
  --purple-500: #9c45e3;
  --purple-600: #8642c2;
  --purple-700: #6e399d;
  --purple-800: #5c2f83;
  --purple-900: #401863;
  --white-overlay-50: rgba(255, 255, 255, 0.09);
  --white-overlay-100: rgba(255, 255, 255, 0.18);
  --white-overlay-200: rgba(255, 255, 255, 0.27);
  --white-overlay-300: rgba(255, 255, 255, 0.36);
  --white-overlay-400: rgba(255, 255, 255, 0.45);
  --white-overlay-500: rgba(255, 255, 255, 0.54);
  --white-overlay-600: rgba(255, 255, 255, 0.63);
  --white-overlay-700: rgba(255, 255, 255, 0.72);
  --white-overlay-800: rgba(255, 255, 255, 0.81);
  --white-overlay-900: rgba(255, 255, 255, 0.9);
  --black-overlay-50: rgba(0, 0, 0, 0.09);
  --black-overlay-100: rgba(0, 0, 0, 0.18);
  --black-overlay-200: rgba(0, 0, 0, 0.27);
  --black-overlay-300: rgba(0, 0, 0, 0.36);
  --black-overlay-400: rgba(0, 0, 0, 0.45);
  --black-overlay-500: rgba(0, 0, 0, 0.54);
  --black-overlay-600: rgba(0, 0, 0, 0.63);
  --black-overlay-700: rgba(0, 0, 0, 0.72);
  --black-overlay-800: rgba(0, 0, 0, 0.81);
  --black-overlay-900: rgba(0, 0, 0, 0.9);
  --linear-black: linear-gradient(
  	to bottom,
  	rgba(46, 46, 46, 0.18) 0%,
  	rgba(36, 36, 36, 0.14) 100%
  );
  --linear-blue: linear-gradient(
  	to bottom,
  	rgba(17, 142, 245, 0.067) 0%,
  	rgba(7, 127, 247, 0.029) 100%
  );
  --angular-white: conic-gradient(rgba(255, 255, 255, 1) 72.38%, rgba(255, 255, 255, 1) 99.87%);
  --angular-black: conic-gradient(rgba(56, 56, 56, 0.22) 72.38%, rgba(56, 56, 56, 0.22) 99.87%);
  --angular-green: conic-gradient(
  	rgba(23, 117, 75, 0.092) 72.38%,
  	rgba(23, 117, 75, 0.092) 99.87%
  );
  --angular-red: conic-gradient(
  	rgba(205, 41, 41, 0.804) 72.38%,
  	rgba(205, 41, 41, 0.804) 99.87%
  );
  --angular-blue: conic-gradient(rgba(0, 110, 219, 0) 72.38%, rgba(0, 110, 219, 0) 99.87%);
}

:root {
  --font-stack: "InterVariable", "Inter", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
  	"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  	sans-serif;
  --text-tiny: 11px;
  --text-2xs: 12px;
  --text-xs: 12px;
  --text-sm: 13px;
  --text-md: 14px;
  --text-base: 14px;
  --text-lg: 16px;
  --text-xl: 18px;
  --text-2xl: 20px;
  --text-3xl: 24px;
  --text-4xl: 26px;
  --text-5xl: 28px;
  --text-6xl: 32px;
  --text-7xl: 40px;
  --text-8xl: 44px;
  --text-9xl: 48px;
  --text-10xl: 52px;
  --text-11xl: 56px;
  --text-12xl: 64px;
  --weight-regular: 420;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-black: 800;
  --text-line-height-3xl: 115%;
  --text-line-height-4xl: 160%;
  --text-line-height-7xl: 140%;
  --text-line-height-12xl: 130%;
  --text-line-height-14xl: 120%;
  --para-line-height-2-xs: 160%;
  --para-line-height-sm: 150%;
  --para-line-height-2xl: 148%;
  --para-line-height-3xl: 140%;
  --heading-color: var(--gray-900);
  --text-neutral: var(--gray-900);
  --text-color: var(--gray-800);
  --text-muted: var(--gray-700);
  --text-light: var(--gray-600);
  --text-dark: var(--fg-color);
}

:root {
  --shadow-xs: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
  	rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  --shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-base: 0px 0px 1px rgba(0, 0, 0, 0.45), 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 0.5px 2px rgba(0, 0, 0, 0.15),
  	0px 2px 3px rgba(0, 0, 0, 0.16);
  --shadow-lg: 0px 0px 1px rgba(0, 0, 0, 0.35), 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 0px 1px rgba(0, 0, 0, 0.19), 0px 1px 2px rgba(0, 0, 0, 0.07),
  	0px 6px 15px -5px rgba(0, 0, 0, 0.11);
  --shadow-2xl: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.05),
  	0px 10px 24px -3px rgba(0, 0, 0, 0.1);
  --focus-default: 0px 0px 0px 2px #c9c9c9;
  --focus-blue: 0px 0px 0px 2px #65b9fc;
  --focus-green: 0px 0px 0px 2px #5bb98c;
  --focus-yellow: 0px 0px 0px 2px #fff0ad;
  --focus-red: 0px 0px 0px 2px #eb9091;
  --custom-status: 0px 0px 0px 1.5px #ffffff;
  --custom-shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.1);
  --drop-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0),
  	0px 2px 4px rgba(0, 0, 0, 0.05);
}

:root {
  --input-padding: 6px 8px;
  --dropdown-padding: 4px 8px;
  --grid-padding: 10px 8px;
  --number-card-padding: 8px 8px 8px 12px;
}

:root {
  --border-radius-tiny: 4px;
  --border-radius-sm: 8px;
  --border-radius: 8px;
  --border-radius-md: 10px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-2xl: 20px;
  --border-radius-full: 999px;
}

.font-size-xs {
  font-size: 0.75rem;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-base {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}

.font-size-4xl {
  font-size: 2.25rem;
}

.font-size-5xl {
  font-size: 3rem;
}

.font-size-6xl {
  font-size: 4rem;
}

:root,
[data-theme=light] {
  --brand-color: var(--primary);
  --padding-xs: 5px;
  --padding-sm: 7px;
  --padding-md: 15px;
  --padding-lg: 20px;
  --padding-xl: 30px;
  --padding-2xl: 40px;
  --margin-xs: 5px;
  --margin-sm: 10px;
  --margin-md: 15px;
  --margin-lg: 20px;
  --margin-xl: 30px;
  --margin-2xl: 40px;
  --page-max-width: 900px;
  --modal-shadow: var(--shadow-md);
  --card-shadow: var(--shadow-sm);
  --btn-shadow: var(--shadow-xs);
  --navbar-height: 48px;
  --icon-fill: transparent;
  --icon-fill-bg: var(--fg-color);
  --icon-stroke: var(--gray-800);
  --bg-blue: var(--blue-100);
  --bg-light-blue: var(--blue-50);
  --bg-dark-blue: var(--blue-300);
  --bg-green: var(--green-100);
  --bg-yellow: var(--yellow-100);
  --bg-orange: var(--orange-100);
  --bg-red: var(--red-100);
  --bg-gray: var(--gray-100);
  --bg-grey: var(--gray-100);
  --bg-light-gray: var(--gray-100);
  --bg-dark-gray: var(--gray-400);
  --bg-darkgrey: var(--gray-400);
  --bg-purple: var(--purple-100);
  --bg-pink: var(--pink-50);
  --bg-cyan: var(--cyan-50);
  --text-on-blue: var(--blue-700);
  --text-on-light-blue: var(--blue-600);
  --text-on-dark-blue: var(--blue-800);
  --text-on-green: var(--green-800);
  --text-on-yellow: var(--yellow-700);
  --text-on-orange: var(--orange-700);
  --text-on-red: var(--red-700);
  --text-on-gray: var(--gray-700);
  --text-on-grey: var(--gray-700);
  --text-on-darkgrey: var(--gray-800);
  --text-on-dark-gray: var(--gray-800);
  --text-on-light-gray: var(--gray-800);
  --text-on-purple: var(--purple-700);
  --text-on-pink: var(--pink-700);
  --text-on-cyan: var(--cyan-700);
  --alert-text-danger: var(--red-600);
  --alert-text-warning: var(--yellow-700);
  --alert-text-info: var(--blue-700);
  --alert-text-success: var(--green-700);
  --alert-bg-danger: var(--red-50);
  --alert-bg-warning: var(--yellow-50);
  --alert-bg-info: var(--blue-50);
  --alert-bg-success: var(--green-100);
  --bg-color: white;
  --fg-color: white;
  --subtle-accent: var(--gray-50);
  --subtle-fg: var(--gray-100);
  --navbar-bg: var(--neutral);
  --fg-hover-color: var(--gray-100);
  --card-bg: var(--fg-color);
  --disabled-text-color: var(--gray-600);
  --disabled-control-bg: var(--gray-50);
  --control-bg: var(--gray-100);
  --control-bg-on-gray: var(--gray-200);
  --awesomebar-focus-bg: var(--fg-color);
  --modal-bg: white;
  --toast-bg: var(--modal-bg);
  --popover-bg: white;
  --awesomplete-hover-bg: var(--control-bg);
  --btn-primary: var(--gray-900);
  --btn-default-bg: var(--gray-100);
  --btn-default-hover-bg: var(--gray-300);
  --border-primary: var(--gray-900);
  --sidebar-select-color: var(--gray-100);
  --scrollbar-thumb-color: var(--gray-400);
  --scrollbar-track-color: var(--gray-200);
  --shadow-inset: inset 0px -1px 0px var(--gray-300);
  --border-color: var(--gray-200);
  --dark-border-color: var(--gray-300);
  --table-border-color: var(--gray-200);
  --highlight-color: var(--gray-50);
  --yellow-highlight-color: var(--yellow-50);
  --btn-group-border-color: var(--gray-300);
  --placeholder-color: var(--gray-50);
  --highlight-shadow: 1px 1px 10px var(--blue-50), 0px 0px 4px var(--blue-600);
  --code-block-bg: var(--gray-900);
  --code-block-text: var(--gray-400);
  --primary-color: var(--gray-900);
  --btn-height: 28px;
  --input-height: 28px;
  --input-disabled-bg: var(--gray-200);
  --checkbox-right-margin: var(--margin-xs);
  --checkbox-size: 14px;
  --checkbox-color: var(--neutral-black);
  --checkbox-focus-shadow: 0 0 0 2px var(--gray-300);
  --checkbox-gradient: linear-gradient(180deg, var(--primary) -124.51%, var(--primary) 100%);
  --checkbox-disabled-gradient: linear-gradient(
  	180deg,
  	var(--disabled-control-bg) -124.51%,
  	var(--disabled-control-bg) 100%
  );
  --switch-bg: var(--gray-300);
  --diff-added: var(--green-100);
  --diff-removed: var(--red-100);
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
}
@media (max-width: 991.98px) {
  :root,
  [data-theme=light] {
    --checkbox-size: 18px;
  }
}

:root {
  --light: #f3f3f3;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.5rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 4rem;
  --card-border-radius: 0.75rem;
}

body {
  --gray-700: #242a30;
  --gray-800: #1c2126;
  --gray-900: #161a1f;
  --background-color: white;
  --sidebar-bg-color: #f8f8f8;
  --sidebar-hover-color: #ebebe9;
  --toc-hover-text-color: var(--gray-900);
  --btn-text-color: var(--gray-900);
  --btn-secondary-color: #f4f5f6;
  --btn-secondary-hover-color: var(--gray-300);
  --active-item-color: #f1f1f0;
  --active-item-text-color: var(--primary);
  --searchbar-color: #f4f5f6;
  --border-color: var(--gray-300);
  --bqoute-border-color: var(--gray-400);
  --bqoute-bg-color: var(--gray-50);
  --code-bg-color: var(--light);
  --code-text-color: var(--purple-600);
  --sidebar-text-color: var(--gray-600);
  --sidebar-hover-color: #f3f3f3;
  --sidebar-active-item-color: #fff;
  --htmldiff-ins-color: #dcfce7;
  --htmldiff-del-color: #fee2e2;
  --editor-button-text-color: var(--gray-700);
  --editor-hover-button-color: var(--gray-100);
  font-family: InterVariable, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  background-color: var(--background-color);
  color: var(--text-color);
  height: auto;
}

body.dark {
  --primary: var(--gray-100);
  --background-color: var(--gray-800);
  --text-color: var(--gray-50);
  --sidebar-bg-color: var(--gray-900);
  --sidebar-hover-color: #242a30;
  --toc-hover-text-color: var(--gray-500);
  --btn-text-color: var(--blue-500);
  --btn-color: #4c5a67;
  --btn-secondary-color: var(--btn-color);
  --btn-secondary-hover-color: var(--gray-500);
  --active-item-color: var(--gray-700);
  --searchbar-color: #242a30;
  --border-color: var(--gray-700);
  --bqoute-border-color: #47474d;
  --bqoute-bg-color: var(--gray-900);
  --code-bg-color: var(--purple-900);
  --code-text-color: var(--purple-50);
  --input-bg-color: #242a30;
  --sidebar-text-color: var(--gray-400);
  --sidebar-hover-color: #242a30;
  --sidebar-active-item-color: var(--gray-700);
  --bg-orange: var(--orange-600);
  --text-on-orange: var(--orange-50);
  --bg-green: var(--green-700);
  --text-on-green: var(--green-50);
  --htmldiff-ins-color: #006400;
  --htmldiff-del-color: #8b0000;
  --editor-button-text-color: var(--gray-400);
  --editor-hover-button-color: var(--gray-700);
}
body.dark .navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
body.dark .alert-message {
  color: var(--gray-800) !important;
}

.nav-tabs {
  font-size: var(--font-size-sm);
}

.wiki-footer {
  border-top: 1px solid var(--border-color);
  margin-top: 1rem;
}
.wiki-footer .btn {
  margin-top: 1rem;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 11px 16px 13px !important;
  width: 48%;
  height: 100%;
  transition: border-color 0.25s;
  box-shadow: unset;
  margin-bottom: 3.5rem;
}
.wiki-footer .btn p {
  line-height: 20px;
  margin: 0;
}
.wiki-footer .btn p:first-child {
  font-size: 12px;
  font-weight: 500;
  color: var(--sidebar-text-color);
}
.wiki-footer .btn p:last-child {
  font-size: 15px;
  font-weight: 500;
  color: var(--primary);
  transition: color 0.25s;
}
.wiki-footer .btn:hover {
  background-color: transparent;
  border: 1px solid var(--primary);
}
.wiki-footer .btn.left {
  margin-left: auto;
  text-align: right;
}
.wiki-footer .btn.right {
  margin-right: auto;
  text-align: left;
}

.drafts {
  border: 1px solid var(--searchbar-color) !important;
  border-radius: 0.5rem;
}
.drafts .table th,
.drafts .table td {
  border-top: 1px solid var(--searchbar-color);
}

.wiki-page-meta {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.revision-message {
  display: inline;
}
@media (max-width: 767.98px) {
  .revision-message {
    display: block;
  }
}

.sidebar-column {
  margin-top: -60px;
}

.sortable-chosen,
.sortable-chosen div,
.sortable-chosen div a {
  cursor: grabbing !important;
}

.sidebar-group-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: none;
  padding: 0px;
}

.sidebar-group-container {
  height: 32px;
  padding: 5px 10px;
  border-radius: 8px;
  gap: 8px;
}

.sidebar-group-title {
  font-size: 15px;
  font-weight: 450;
  line-height: 16px;
  letter-spacing: 0.015em;
  text-align: right;
}

.sidebar-group-item-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0px 10px;
  margin-right: 10px;
  margin-bottom: 10px !important;
  border-right: 1px solid var(--border-color);
}

.sidebar-group-item {
  display: flex;
  align-items: center;
  height: 26px;
  border-radius: 8px;
}

.sidebar-item-active a {
  background-color: transparent !important;
  color: var(--text-color) !important;
}

.sidebar-group-item-title {
  color: var(--text-light);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar-group {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  /* identical to box height, or 28px */
  letter-spacing: -0.011em;
}
.sidebar-group ul {
  padding-right: 0.25rem;
}
.sidebar-group .list-unstyled:empty::after {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  color: var(--sidebar-text-color);
  content: "This Wiki Group will be deleted automatically";
}
.sidebar-group .collapsible {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  width: 100%;
}
.sidebar-group div .h6 {
  font-size: 0.875rem;
  margin-bottom: 0;
  line-height: 1.5rem;
  color: var(--text-color);
  font-weight: 700;
}
.sidebar-group .drop-icon,
.sidebar-group .add-sidebar-page {
  cursor: pointer;
  display: inline-flex;
  margin: 0 auto 0 5px;
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
  color: var(--sidebar-text-color);
}
.sidebar-group .drop-icon.rotate,
.sidebar-group .add-sidebar-page.rotate {
  transform: rotate(90deg);
}

.sidebar-group .collapsible:hover,
.sidebar-item:hover {
  cursor: pointer;
}
.sidebar-group .collapsible:hover:not(.active),
.sidebar-item:hover:not(.active) {
  background-color: var(--sidebar-hover-color);
  border-radius: 0.625rem;
}

.sidebar-item {
  display: flex;
  align-items: center;
  min-height: 1.75rem;
}
.sidebar-item.active {
  background-color: var(--sidebar-active-item-color);
  border-radius: 0.625rem;
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.sidebar-item div {
  display: flex;
  align-items: center;
}
.sidebar-item a {
  margin: 0;
  font-weight: 420;
  width: 100%;
  padding: 5px 8px;
}
.sidebar-item a:hover {
  color: unset;
}
.sidebar-item :first-child {
  width: 100%;
}

.sm-sidebar {
  width: 100%;
}

.sm-sidebar .web-sidebar {
  margin-top: 4px;
  padding-bottom: 2rem;
}

.web-sidebar {
  position: relative;
}

.doc-sidebar {
  margin-bottom: 0;
  height: 100vh;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}
.doc-sidebar .web-sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 17rem;
}
.doc-sidebar .web-sidebar .sidebar-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 992px) {
  .doc-sidebar {
    top: 0;
    padding-bottom: 0;
  }
}
.navbar-nav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--background-color);
  padding: 0 14px;
}
@media (max-width: 767.98px) {
  .navbar-nav {
    max-width: 100vw;
    height: auto;
    align-items: flex-start;
  }
}
.navbar-nav .search-item {
  margin-left: auto;
  height: auto !important;
}
.navbar-nav .dropdown-menu {
  position: sticky;
  border: 1px solid var(--border-color);
}
.navbar-nav .dropdown-menu .dropdown-item {
  color: var(--text-color);
}
.navbar-nav .dropdown-menu .dropdown-item:focus-visible {
  outline: none;
}
.navbar-nav .dropdown-menu .dropdown-item:hover {
  background-color: var(--sidebar-hover-color);
}
.navbar-nav .dropdown-menu .dropdown-item:hover .h6 {
  color: var(--background-color) !important;
}

.nav-item {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.nav-item #search-container {
  padding-left: 0px;
  padding-right: 0px;
}
.nav-item #search-container .dropdown {
  height: 32px;
  width: 240px;
  background-color: var(--searchbar-color);
}
.nav-item #search-container .dropdown:hover {
  border-color: var(--primary);
}
.nav-item #search-container .dropdown kbd {
  position: absolute;
  top: 7px;
  left: 5px;
  padding: 0.1rem 0.4rem;
  color: var(--sidebar-text-color);
  background-color: transparent;
}
.nav-item #search-container .dropdown span {
  margin-right: 2rem;
  margin-left: 3rem;
}
.nav-item select {
  height: 100%;
}

#searchModal > div:first-child {
  margin-top: 5rem;
}
#searchModal b.match {
  background-color: transparent;
  color: var(--primary);
  font-weight: 500;
  padding: 0;
}
#searchModal input {
  height: 3rem;
  background: var(--searchbar-color);
  border-radius: 4px;
  padding-right: 2.5rem;
  font-size: inherit;
}
#searchModal .search-icon {
  position: absolute;
  padding: 0.7rem 0.5rem 0 0;
}
#searchModal svg {
  color: #74808b;
}
#searchModal .modal-body .text-muted {
  margin: auto;
  width: -moz-max-content;
  width: max-content;
}
#searchModal .modal-body .dropdown-border {
  height: 1px;
  margin: 0 0.5rem;
  background-color: var(--border-color);
}
#searchModal .modal-body .dropdown-border:last-child {
  background-color: unset;
}
#searchModal .modal-header {
  border-bottom-left-radius: calc(var(--border-radius-lg) - 1px);
  border-bottom-right-radius: calc(var(--border-radius-lg) - 1px);
}
#searchModal .dropdown-item {
  font-size: 15px;
  outline: unset;
  overflow: auto;
  margin: 0.5rem 0;
  white-space: inherit;
  color: var(--text-color);
}
#searchModal .dropdown-item:active {
  color: unset;
  background-color: unset;
}
#searchModal .dropdown-item:focus, #searchModal .dropdown-item:hover {
  background-color: var(--active-item-color);
}
#searchModal .dropdown-item h6 {
  font-size: 15px;
}

.wiki-navbar {
  background-color: transparent;
  padding: 0px !important;
  border-bottom: 1px solid var(--border-color);
}
@media (max-width: 991.98px) {
  .wiki-navbar {
    width: auto;
    padding-right: 2rem;
  }
}
.wiki-navbar .wiki-navbar-container {
  padding-left: 1rem;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-color);
}
@media (max-width: 991.98px) {
  .wiki-navbar .wiki-navbar-container {
    box-shadow: unset;
    margin-right: 0;
  }
  .wiki-navbar .wiki-navbar-container .navbar-nav {
    padding-right: 10px !important;
    max-width: 100vw;
  }
}
.wiki-navbar .doc-container .navbar-collapse {
  padding-top: 2rem;
  background-color: var(--background-color);
  margin-right: 2rem;
  padding-bottom: 1rem;
}
@media (max-width: 991.98px) {
  .wiki-navbar .doc-container .navbar-collapse {
    padding-top: 0;
    margin: 0;
  }
}
.wiki-navbar .container {
  height: 36px;
}
.wiki-navbar .sun-moon-container {
  cursor: pointer;
  margin-right: 24px;
  display: flex;
  align-items: center;
}
.wiki-navbar .sun-moon-container svg {
  width: 16px !important;
}
@media (max-width: 991.98px) {
  .wiki-navbar .sun-moon-container {
    margin: 0.25rem 0.5rem 1rem 0;
  }
}
.wiki-navbar .mobile-search-icon {
  margin: 0 auto 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.doc-layout {
  padding-top: 0rem;
  margin: 0;
  max-width: 100%;
}

.navbar-brand {
  padding: 0;
  color: var(--text-color) !important;
}
.navbar-brand img {
  height: 20px;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
@media (max-width: 991.98px) {
  .navbar-brand {
    border-bottom: unset;
  }
}

@media (max-width: 991.98px) {
  .container-fluid.doc-layout.doc-container {
    border-bottom: 1px solid var(--border-color);
  }
}

.navbar-brand-container {
  width: 17rem;
  display: flex;
  align-items: center;
  padding: 10px 18px;
  background-color: var(--sidebar-bg-color);
  position: sticky;
  top: 0;
  z-index: 5;
  height: 60px;
}
@media (max-width: 991.98px) {
  .navbar-brand-container {
    max-width: 14rem;
    background-color: var(--background-color);
  }
}

.dropdown-menu {
  background-color: var(--background-color);
}
.dropdown-menu .dropdown-item {
  color: var(--text-color);
}
.dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item:hover {
  background-color: var(--sidebar-hover-color);
  color: var(--text-color);
}

.main-column {
  background-color: var(--background-color);
  width: 745px;
  padding: 10px 10px;
  margin: 0 auto;
}
@media (max-width: 991.98px) {
  .main-column {
    padding: 20px;
    width: 100vw;
  }
}

.wiki-content .from-markdown > :first-child {
  margin-top: 0;
}

.wiki-page-content {
  max-width: 750px;
  margin: 0.5rem auto;
}
.wiki-page-content .from-markdown .edit-wiki-button {
  cursor: pointer;
}
.wiki-page-content .from-markdown p {
  font-size: 15px;
  font-weight: 420;
  line-height: 155%;
  letter-spacing: 0.02em;
  text-align: right;
}
.wiki-page-content .from-markdown h2 {
  margin-top: 2.5rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: right;
}
.wiki-page-content .from-markdown h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.015em;
  text-align: right;
}
.wiki-page-content .from-markdown h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: right;
}
.wiki-page-content .from-markdown a.btn-primary-light {
  background-color: var(--btn-secondary-color) !important;
}
.wiki-page-content .from-markdown ul[data-type=taskList] input {
  margin-top: 0;
}
.wiki-page-content .from-markdown li {
  font-size: 15px;
  font-weight: 420;
  line-height: 155%;
  letter-spacing: 0.02em;
  text-align: right;
}
.wiki-page-content .from-markdown li::marker {
  font-size: 15px;
}
@media (max-width: 991.98px) {
  .wiki-page-content {
    width: auto;
    margin: unset;
  }
}

.wiki-editor .wiki-title-container {
  margin-bottom: 1rem;
}
.wiki-editor .wiki-title-input {
  color: var(--text-color);
  background-color: var(--editor-background-color);
}

.feather-link {
  visibility: hidden;
}

.wiki-page-content .from-markdown h1::before,
.wiki-page-content .from-markdown h2::before,
.wiki-page-content .from-markdown h3::before,
.wiki-page-content .from-markdown h4::before,
.wiki-page-content .from-markdown h5::before,
.wiki-page-content .from-markdown h6::before {
  height: 0;
  margin-top: 0;
}

.hljs {
  overflow-x: visible;
}

h2:hover .feather-link,
h3:hover .feather-link,
h4:hover .feather-link,
h5:hover .feather-link,
h6:hover .feather-link {
  visibility: visible;
}

.btn.btn-secondary {
  background-color: var(--btn-secondary-color);
}
.btn.btn-secondary:hover {
  background-color: var(--btn-secondary-hover-color);
}

.feedback-btn {
  cursor: pointer;
}
.feedback-btn:hover {
  text-decoration: underline;
}

.feedback-modal {
  width: 25rem;
}
.feedback-modal .form-control:focus {
  border: none;
}
.feedback-modal .rating-options-buttons {
  display: grid;
  border-radius: 6px;
  overflow: hidden;
  border: 1.5px solid #000;
}
.feedback-modal .rating-options-buttons > .ratings-number {
  border-left: 1px solid #000;
}
.feedback-modal .rating-options-buttons > .ratings-number:last-child {
  border-left: none;
}
.feedback-modal .ratings-number {
  font-size: 15px;
  padding: 8px 0px;
  border: none;
  color: #000;
  background-color: #fff;
}
.feedback-modal .ratings-number.rating-active {
  background-color: #000;
  color: #fff;
}
.feedback-modal .submit-feedback-btn.disabled {
  pointer-events: none;
}

.dropdown-toggle.wiki-options::after {
  display: none;
}

.wiki-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}
.wiki-options svg {
  width: 2rem;
}
.wiki-options .dropdown-menu {
  min-width: 7.5rem;
}

.wiki-options:hover {
  background-color: var(--gray-200);
  border-radius: 5px;
}

.dark .wiki-options:hover {
  background-color: var(--gray-700);
}

.forward-back a p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.page-toc {
  background-color: var(--background-color);
  font-size: 0.75rem;
  position: sticky;
  top: 90px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  height: 90vh;
  margin-right: auto;
  width: 240px;
  margin-left: 16px;
  padding-bottom: 10rem;
}
.page-toc::-webkit-scrollbar {
  display: none;
}
.page-toc .page-toc-title {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.09em;
  text-align: right;
  margin-bottom: 0.75rem;
}
.page-toc h5 {
  font-size: 0.75rem;
  padding-right: 1rem;
  letter-spacing: 0.4px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}
.page-toc div {
  width: 100%;
  padding: 0;
  top: 0;
  border-right: 1px solid var(--border-color);
}
.page-toc div ul {
  padding-bottom: 0;
  margin-bottom: 0;
}
.page-toc div ul li a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.page-toc .active {
  color: var(--text-color);
  box-shadow: -1px 0 0 var(--primary) inset;
  transition: color 0.2s, box-shadow 0.2s linear, transform 0.2s linear;
}
.page-toc a {
  font-size: 13px;
  padding: 0.25rem;
  color: var(--sidebar-text-color);
  transform: translateX(1px);
}
.page-toc a:hover {
  color: var(--toc-hover-text-color);
}

.doc-sidebar {
  background-color: var(--sidebar-bg-color);
}

.user-contributions {
  margin: 0 auto 0 0;
  color: var(--sidebar-text-color);
  font-size: 0.8rem;
}

/*
====
navbar
====
*/
.navbar .navbar-expand-lg {
  width: 100%;
  position: fixed;
  top: 0;
  /*ensure navbar stays affixes to the top*/
  right: 0;
  left: 0;
}
.navbar .navbar-link {
  color: var(--text-color);
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 0;
  display: block;
}
.navbar .navbar-link:hover {
  color: var(--primary);
  text-decoration: none;
}
.navbar .navbar-toggler {
  border-color: transparent;
  padding: 18px;
}
.navbar .navbar-toggler:focus {
  outline: unset;
}
.navbar .logged-in {
  display: flex;
  align-items: center;
}
.navbar .logged-in .nav-avatar {
  padding: 0;
}

.form-control:focus {
  color: var(--text-color);
  border: 1px solid var(--gray-500);
}

@media (max-width: 991.98px) {
  .navbar {
    position: inherit;
  }
  .navbar-expand-lg .doc-container {
    padding: 0.5rem 0;
  }
  .web-sidebar {
    padding-top: 0;
  }
  .web-sidebar > a {
    display: none;
  }
  .wiki-footer .btn.left,
  .wiki-footer .btn.right {
    width: 100%;
    margin-bottom: 10px;
  }
  .nav-item {
    margin-right: 0.5rem;
  }
  .nav-item #search-container {
    margin: 1rem 0;
    width: 140%;
  }
  .search-nav-item {
    height: 2.2rem;
  }
}
@media (min-width: 768px) {
  .navbar-light {
    border-bottom: 0;
  }
}
.my-contributions,
.new-wiki-page,
.sidebar-edit-mode-btn,
.sidebar-view-mode-btn,
.add-sidebar-group {
  cursor: pointer;
  margin: auto;
  font-weight: 500;
}
.my-contributions svg,
.new-wiki-page svg,
.sidebar-edit-mode-btn svg,
.sidebar-view-mode-btn svg,
.add-sidebar-group svg {
  margin-bottom: 0.1rem;
}
.my-contributions span,
.new-wiki-page span,
.sidebar-edit-mode-btn span,
.sidebar-view-mode-btn span,
.add-sidebar-group span {
  font-size: 0.75rem;
}

.sidebar-options {
  bottom: 0;
  position: sticky;
  padding: 0.5rem;
  background-color: var(--sidebar-bg-color);
}

.web-footer {
  border-top: 1px solid var(--border-color);
  background-color: var(--background-color);
}

.search-nav-item {
  position: relative;
}
.search-nav-item svg {
  top: 10px;
  left: 12px;
  position: absolute;
}

.contributions-header {
  margin: 2rem 0 1.5rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 16px;
}

.count {
  font-size: var(--text-xs);
  background-color: var(--gray-500);
  border-radius: var(--border-radius-sm);
  color: var(--gray-50);
  padding: 0 var(--padding-xs);
  float: left;
  margin-top: 2px;
}

img::after {
  content: "";
}

img[alt]::after {
  width: unset;
  height: unset;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: var(--text-color) !important;
}

h1 {
  margin-top: 3rem;
  margin-bottom: 0.75rem;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.005em;
}
@media (min-width: 576px) {
  h1 {
    margin-top: 1.5rem !important;
    margin-bottom: 0 !important;
  }
}

h2 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  h2 {
    margin-top: 4rem;
    margin-bottom: 0.75rem;
  }
}

h3 {
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  h3 {
    margin-top: 2.5rem;
  }
}

h4 {
  font-size: 1.125rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  h4 {
    margin-top: 2.5rem;
  }
}
h4 a {
  color: #525252;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

p {
  font-size: 1rem;
}

.modal .modal-content {
  background-color: var(--background-color);
}
.modal .modal-header {
  border-bottom: unset;
}
.modal .modal-header .close {
  font-weight: 400;
}
.modal .modal-body {
  padding-top: 0;
}
.modal .modal-body label {
  color: var(--text-color);
}
.modal .modal-body input {
  width: 100%;
  background: #ededed;
  border-radius: 0.375rem;
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem;
  font-size: 13px;
  line-height: 1.25rem;
}
.modal .modal-body input[type=checkbox] {
  color: #000;
  padding: 0%;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  accent-color: black;
}
.modal .modal-body input[type=checkbox]:checked {
  background-color: var(--primary);
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>"), var(--checkbox-gradient);
  background-size: 57%, 100%;
  box-shadow: none;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
}
.modal .modal-body input,
.modal .modal-body textarea {
  background-color: var(--searchbar-color);
  color: var(--text-color);
}
.modal .modal-footer {
  border-top: unset;
  justify-content: end;
}
.modal .modal-footer .btn {
  width: 100%;
}

.wiki-revision-list {
  padding-bottom: 1rem;
  margin: auto;
}
.wiki-revision-list .wiki-revision-item {
  list-style-type: none;
  margin-bottom: 1rem;
}

.revisions-modal .feather-link {
  display: none;
}
.revisions-modal .revision-content {
  height: 500px;
  overflow-y: auto;
}
.revisions-modal .modal-header {
  position: inherit;
}
.revisions-modal .modal-header .modal-title {
  max-width: 100%;
}
.revisions-modal .modal-body {
  padding: 1rem;
}
.revisions-modal .modal-body .wiki-content {
  min-height: unset;
}
.revisions-modal .modal-footer {
  position: inherit;
}
.revisions-modal .modal-footer .previous-revision {
  width: inherit;
  margin-left: auto;
}
.revisions-modal .modal-footer .next-revision {
  width: inherit;
  margin-right: auto;
}

del.diffmod, del.diffdel {
  all: unset;
}
del.diffmod *, del.diffdel * {
  border: 4px var(--htmldiff-del-color) solid !important;
  border-radius: 5px;
}

ins.diffmod, ins.diffins {
  all: unset;
}
ins.diffmod *, ins.diffins * {
  border: 4px var(--htmldiff-ins-color) solid !important;
  border-radius: 5px;
}

.text-sm {
  font-size: 13px !important;
  line-height: 1.15;
  letter-spacing: 0.02em;
}

.text-xs {
  font-size: 12px;
  line-height: 1.15;
  letter-spacing: 0.02em;
  font-weight: 400;
}

.wiki-space-route-block {
  padding: 0.4rem 0.5rem;
  border-radius: 0.375rem;
  background-color: var(--gray-300);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:root {
  --neutral-white: #ffffff;
  --neutral-black: #000000;
  --neutral: var(--neutral-white);
  --invert-neutral: var(--neutral-black);
  --gray-50: #f8f8f8;
  --gray-100: #f3f3f3;
  --gray-200: #ededed;
  --gray-300: #e2e2e2;
  --gray-400: #c7c7c7;
  --gray-500: #999999;
  --gray-600: #7c7c7c;
  --gray-700: #525252;
  --gray-800: #383838;
  --gray-900: #171717;
  --blue-50: #f7fbfd;
  --blue-100: #edf6fd;
  --blue-200: #e3f1fd;
  --blue-300: #c9e7fc;
  --blue-400: #70b6f0;
  --blue-500: #0289f7;
  --blue-600: #007be0;
  --blue-700: #0070cc;
  --blue-800: #005ca3;
  --blue-900: #004880;
  --green-50: #f3fcf5;
  --green-100: #e4f5e9;
  --green-200: #daf0e1;
  --green-300: #cae5d4;
  --green-400: #b6dec5;
  --green-500: #59ba8b;
  --green-600: #30a66d;
  --green-700: #278f5e;
  --green-800: #16794c;
  --green-900: #173b2c;
  --red-50: #fff7f7;
  --red-100: #fff0f0;
  --red-200: #fcd7d7;
  --red-300: #f9c6c6;
  --red-400: #eb9091;
  --red-500: #e03636;
  --red-600: #cc2929;
  --red-700: #b52a2a;
  --red-800: #941f1f;
  --red-900: #6b1515;
  --orange-50: #fff9f5;
  --orange-100: #fff1e7;
  --orange-200: #fce6d5;
  --orange-300: #f7d6bd;
  --orange-400: #f0b58b;
  --orange-500: #e86c13;
  --orange-600: #d45a08;
  --orange-700: #bd3e0c;
  --orange-800: #9e3513;
  --orange-900: #6b2711;
  --amber-50: #fdfaed;
  --amber-100: #fcf3cf;
  --amber-200: #f7e28d;
  --amber-300: #f5d261;
  --amber-400: #f2be3a;
  --amber-500: #e79913;
  --amber-600: #db7706;
  --amber-700: #b35309;
  --amber-800: #91400d;
  --amber-900: #763813;
  --yellow-50: #fffcef;
  --yellow-100: #fff7d3;
  --yellow-200: #f7e9a8;
  --yellow-300: #f5e171;
  --yellow-400: #f2d14b;
  --yellow-500: #edba13;
  --yellow-600: #d1930d;
  --yellow-700: #ab6e05;
  --yellow-800: #8c5600;
  --yellow-900: #733f12;
  --cyan-50: #f5fbfc;
  --cyan-100: #e0f8ff;
  --cyan-200: #b3ecfc;
  --cyan-300: #94e6ff;
  --cyan-400: #6bd3f2;
  --cyan-500: #34bae3;
  --cyan-600: #32a4c7;
  --cyan-700: #267a94;
  --cyan-800: #125c73;
  --cyan-900: #164759;
  --teal-50: #f0fdfa;
  --teal-100: #e6f7f4;
  --teal-200: #bae8e1;
  --teal-300: #97ded4;
  --teal-400: #73d1c4;
  --teal-500: #36baad;
  --teal-600: #0b9e92;
  --teal-700: #0f736b;
  --teal-800: #115c57;
  --teal-900: #114541;
  --violet-50: #fbfaff;
  --violet-100: #f5f2ff;
  --violet-200: #e5e1fa;
  --violet-300: #dad2f7;
  --violet-400: #bdb1f0;
  --violet-500: #6846e3;
  --violet-600: #5f46c7;
  --violet-700: #4f3da1;
  --violet-800: #392980;
  --violet-900: #251959;
  --pink-50: #fff7fc;
  --pink-100: #feeef8;
  --pink-200: #f8e2f0;
  --pink-300: #f2d4e6;
  --pink-400: #e9c4da;
  --pink-500: #e34aa6;
  --pink-600: #cf3a96;
  --pink-700: #9c2671;
  --pink-800: #801458;
  --pink-900: #570f3e;
  --purple-50: #fdfaff;
  --purple-100: #f9f0ff;
  --purple-200: #f1e5fa;
  --purple-300: #e9d6f5;
  --purple-400: #d6c1e6;
  --purple-500: #9c45e3;
  --purple-600: #8642c2;
  --purple-700: #6e399d;
  --purple-800: #5c2f83;
  --purple-900: #401863;
  --white-overlay-50: rgba(255, 255, 255, 0.09);
  --white-overlay-100: rgba(255, 255, 255, 0.18);
  --white-overlay-200: rgba(255, 255, 255, 0.27);
  --white-overlay-300: rgba(255, 255, 255, 0.36);
  --white-overlay-400: rgba(255, 255, 255, 0.45);
  --white-overlay-500: rgba(255, 255, 255, 0.54);
  --white-overlay-600: rgba(255, 255, 255, 0.63);
  --white-overlay-700: rgba(255, 255, 255, 0.72);
  --white-overlay-800: rgba(255, 255, 255, 0.81);
  --white-overlay-900: rgba(255, 255, 255, 0.9);
  --black-overlay-50: rgba(0, 0, 0, 0.09);
  --black-overlay-100: rgba(0, 0, 0, 0.18);
  --black-overlay-200: rgba(0, 0, 0, 0.27);
  --black-overlay-300: rgba(0, 0, 0, 0.36);
  --black-overlay-400: rgba(0, 0, 0, 0.45);
  --black-overlay-500: rgba(0, 0, 0, 0.54);
  --black-overlay-600: rgba(0, 0, 0, 0.63);
  --black-overlay-700: rgba(0, 0, 0, 0.72);
  --black-overlay-800: rgba(0, 0, 0, 0.81);
  --black-overlay-900: rgba(0, 0, 0, 0.9);
  --linear-black: linear-gradient(
  	to bottom,
  	rgba(46, 46, 46, 0.18) 0%,
  	rgba(36, 36, 36, 0.14) 100%
  );
  --linear-blue: linear-gradient(
  	to bottom,
  	rgba(17, 142, 245, 0.067) 0%,
  	rgba(7, 127, 247, 0.029) 100%
  );
  --angular-white: conic-gradient(rgba(255, 255, 255, 1) 72.38%, rgba(255, 255, 255, 1) 99.87%);
  --angular-black: conic-gradient(rgba(56, 56, 56, 0.22) 72.38%, rgba(56, 56, 56, 0.22) 99.87%);
  --angular-green: conic-gradient(
  	rgba(23, 117, 75, 0.092) 72.38%,
  	rgba(23, 117, 75, 0.092) 99.87%
  );
  --angular-red: conic-gradient(
  	rgba(205, 41, 41, 0.804) 72.38%,
  	rgba(205, 41, 41, 0.804) 99.87%
  );
  --angular-blue: conic-gradient(rgba(0, 110, 219, 0) 72.38%, rgba(0, 110, 219, 0) 99.87%);
}

:root {
  --font-stack: "InterVariable", "Inter", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
  	"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  	sans-serif;
  --text-tiny: 11px;
  --text-2xs: 12px;
  --text-xs: 12px;
  --text-sm: 13px;
  --text-md: 14px;
  --text-base: 14px;
  --text-lg: 16px;
  --text-xl: 18px;
  --text-2xl: 20px;
  --text-3xl: 24px;
  --text-4xl: 26px;
  --text-5xl: 28px;
  --text-6xl: 32px;
  --text-7xl: 40px;
  --text-8xl: 44px;
  --text-9xl: 48px;
  --text-10xl: 52px;
  --text-11xl: 56px;
  --text-12xl: 64px;
  --weight-regular: 420;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-black: 800;
  --text-line-height-3xl: 115%;
  --text-line-height-4xl: 160%;
  --text-line-height-7xl: 140%;
  --text-line-height-12xl: 130%;
  --text-line-height-14xl: 120%;
  --para-line-height-2-xs: 160%;
  --para-line-height-sm: 150%;
  --para-line-height-2xl: 148%;
  --para-line-height-3xl: 140%;
  --heading-color: var(--gray-900);
  --text-neutral: var(--gray-900);
  --text-color: var(--gray-800);
  --text-muted: var(--gray-700);
  --text-light: var(--gray-600);
  --text-dark: var(--fg-color);
}

:root {
  --shadow-xs: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
  	rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  --shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-base: 0px 0px 1px rgba(0, 0, 0, 0.45), 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 0.5px 2px rgba(0, 0, 0, 0.15),
  	0px 2px 3px rgba(0, 0, 0, 0.16);
  --shadow-lg: 0px 0px 1px rgba(0, 0, 0, 0.35), 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 0px 1px rgba(0, 0, 0, 0.19), 0px 1px 2px rgba(0, 0, 0, 0.07),
  	0px 6px 15px -5px rgba(0, 0, 0, 0.11);
  --shadow-2xl: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.05),
  	0px 10px 24px -3px rgba(0, 0, 0, 0.1);
  --focus-default: 0px 0px 0px 2px #c9c9c9;
  --focus-blue: 0px 0px 0px 2px #65b9fc;
  --focus-green: 0px 0px 0px 2px #5bb98c;
  --focus-yellow: 0px 0px 0px 2px #fff0ad;
  --focus-red: 0px 0px 0px 2px #eb9091;
  --custom-status: 0px 0px 0px 1.5px #ffffff;
  --custom-shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.1);
  --drop-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0),
  	0px 2px 4px rgba(0, 0, 0, 0.05);
}

:root {
  --input-padding: 6px 8px;
  --dropdown-padding: 4px 8px;
  --grid-padding: 10px 8px;
  --number-card-padding: 8px 8px 8px 12px;
}

:root {
  --border-radius-tiny: 4px;
  --border-radius-sm: 8px;
  --border-radius: 8px;
  --border-radius-md: 10px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-2xl: 20px;
  --border-radius-full: 999px;
}

.font-size-xs {
  font-size: 0.75rem;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-base {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}

.font-size-4xl {
  font-size: 2.25rem;
}

.font-size-5xl {
  font-size: 3rem;
}

.font-size-6xl {
  font-size: 4rem;
}

:root,
[data-theme=light] {
  --brand-color: var(--primary);
  --padding-xs: 5px;
  --padding-sm: 7px;
  --padding-md: 15px;
  --padding-lg: 20px;
  --padding-xl: 30px;
  --padding-2xl: 40px;
  --margin-xs: 5px;
  --margin-sm: 10px;
  --margin-md: 15px;
  --margin-lg: 20px;
  --margin-xl: 30px;
  --margin-2xl: 40px;
  --page-max-width: 900px;
  --modal-shadow: var(--shadow-md);
  --card-shadow: var(--shadow-sm);
  --btn-shadow: var(--shadow-xs);
  --navbar-height: 48px;
  --icon-fill: transparent;
  --icon-fill-bg: var(--fg-color);
  --icon-stroke: var(--gray-800);
  --bg-blue: var(--blue-100);
  --bg-light-blue: var(--blue-50);
  --bg-dark-blue: var(--blue-300);
  --bg-green: var(--green-100);
  --bg-yellow: var(--yellow-100);
  --bg-orange: var(--orange-100);
  --bg-red: var(--red-100);
  --bg-gray: var(--gray-100);
  --bg-grey: var(--gray-100);
  --bg-light-gray: var(--gray-100);
  --bg-dark-gray: var(--gray-400);
  --bg-darkgrey: var(--gray-400);
  --bg-purple: var(--purple-100);
  --bg-pink: var(--pink-50);
  --bg-cyan: var(--cyan-50);
  --text-on-blue: var(--blue-700);
  --text-on-light-blue: var(--blue-600);
  --text-on-dark-blue: var(--blue-800);
  --text-on-green: var(--green-800);
  --text-on-yellow: var(--yellow-700);
  --text-on-orange: var(--orange-700);
  --text-on-red: var(--red-700);
  --text-on-gray: var(--gray-700);
  --text-on-grey: var(--gray-700);
  --text-on-darkgrey: var(--gray-800);
  --text-on-dark-gray: var(--gray-800);
  --text-on-light-gray: var(--gray-800);
  --text-on-purple: var(--purple-700);
  --text-on-pink: var(--pink-700);
  --text-on-cyan: var(--cyan-700);
  --alert-text-danger: var(--red-600);
  --alert-text-warning: var(--yellow-700);
  --alert-text-info: var(--blue-700);
  --alert-text-success: var(--green-700);
  --alert-bg-danger: var(--red-50);
  --alert-bg-warning: var(--yellow-50);
  --alert-bg-info: var(--blue-50);
  --alert-bg-success: var(--green-100);
  --bg-color: white;
  --fg-color: white;
  --subtle-accent: var(--gray-50);
  --subtle-fg: var(--gray-100);
  --navbar-bg: var(--neutral);
  --fg-hover-color: var(--gray-100);
  --card-bg: var(--fg-color);
  --disabled-text-color: var(--gray-600);
  --disabled-control-bg: var(--gray-50);
  --control-bg: var(--gray-100);
  --control-bg-on-gray: var(--gray-200);
  --awesomebar-focus-bg: var(--fg-color);
  --modal-bg: white;
  --toast-bg: var(--modal-bg);
  --popover-bg: white;
  --awesomplete-hover-bg: var(--control-bg);
  --btn-primary: var(--gray-900);
  --btn-default-bg: var(--gray-100);
  --btn-default-hover-bg: var(--gray-300);
  --border-primary: var(--gray-900);
  --sidebar-select-color: var(--gray-100);
  --scrollbar-thumb-color: var(--gray-400);
  --scrollbar-track-color: var(--gray-200);
  --shadow-inset: inset 0px -1px 0px var(--gray-300);
  --border-color: var(--gray-200);
  --dark-border-color: var(--gray-300);
  --table-border-color: var(--gray-200);
  --highlight-color: var(--gray-50);
  --yellow-highlight-color: var(--yellow-50);
  --btn-group-border-color: var(--gray-300);
  --placeholder-color: var(--gray-50);
  --highlight-shadow: 1px 1px 10px var(--blue-50), 0px 0px 4px var(--blue-600);
  --code-block-bg: var(--gray-900);
  --code-block-text: var(--gray-400);
  --primary-color: var(--gray-900);
  --btn-height: 28px;
  --input-height: 28px;
  --input-disabled-bg: var(--gray-200);
  --checkbox-right-margin: var(--margin-xs);
  --checkbox-size: 14px;
  --checkbox-color: var(--neutral-black);
  --checkbox-focus-shadow: 0 0 0 2px var(--gray-300);
  --checkbox-gradient: linear-gradient(180deg, var(--primary) -124.51%, var(--primary) 100%);
  --checkbox-disabled-gradient: linear-gradient(
  	180deg,
  	var(--disabled-control-bg) -124.51%,
  	var(--disabled-control-bg) 100%
  );
  --switch-bg: var(--gray-300);
  --diff-added: var(--green-100);
  --diff-removed: var(--red-100);
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
}
@media (max-width: 991.98px) {
  :root,
  [data-theme=light] {
    --checkbox-size: 18px;
  }
}

:root {
  --light: #f3f3f3;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.5rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 4rem;
  --card-border-radius: 0.75rem;
}

body {
  --icon-stroke: var(--text-color);
  --editor-line-no-bg-color: var(--gray-100);
  --editor-background-color: #f3f3f3;
}

body.dark {
  --editor-line-no-bg-color: var(--gray-800);
  --control-bg-on-gray: var(--gray-700);
  --editor-background-color: #333c44;
}
body.dark .draft-wiki-page {
  background: var(--gray-700);
  color: var(--gray-50);
}

.form-control {
  background-color: white;
}

.remove-sidebar-item {
  cursor: pointer;
  margin: 0 auto 0 1rem;
}

.collapsible .remove-sidebar-item {
  margin-left: calc(15px - 0.5rem);
  margin-bottom: 3px;
}

.trash-icon {
  visibility: hidden;
}

.sidebar-item:hover .trash-icon,
.sidebar-group .collapsible:hover .trash-icon {
  visibility: visible;
}

.wiki-editor,
.from-markdown {
  margin: auto;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

.wiki-editor,
.wiki-content {
  font-size: 1rem;
  color: var(--editor-button-text-color);
  line-height: 1.7;
  max-width: calc(100vw - 6rem);
}
.wiki-editor a:not(.dropdown-item, .btn),
.wiki-content a:not(.dropdown-item, .btn) {
  color: var(--text-color);
  text-decoration: underline;
}
.wiki-editor p,
.wiki-content p {
  color: var(--text-color);
}
.wiki-editor ul,
.wiki-editor ol,
.wiki-content ul,
.wiki-content ol {
  padding-right: 2rem;
}
.wiki-editor ul,
.wiki-content ul {
  list-style-type: disc;
}
.wiki-editor ol,
.wiki-content ol {
  list-style: decimal;
}
.wiki-editor li,
.wiki-content li {
  color: var(--text-color);
  padding-top: 1px;
  padding-bottom: 1px;
}
.wiki-editor li::marker,
.wiki-content li::marker {
  color: var(--text-color);
}
.wiki-editor li p,
.wiki-content li p {
  margin-bottom: 0;
}
.wiki-editor li > ul,
.wiki-editor li > ol,
.wiki-content li > ul,
.wiki-content li > ol {
  padding-right: 1.5rem;
}
.wiki-editor ul > li:first-child,
.wiki-content ul > li:first-child {
  margin-top: 3px !important;
}
.wiki-editor ul > * + *,
.wiki-editor ol > * + *,
.wiki-content ul > * + *,
.wiki-content ol > * + * {
  margin-top: 2px !important;
}
.wiki-editor blockquote,
.wiki-content blockquote {
  padding: 0.75rem 1.25rem 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid var(--bqoute-border-color);
  border-right: 5px solid var(--bqoute-border-color);
  border-radius: 0.5rem;
  margin: 1.5rem 0 !important;
  background-color: var(--bqoute-bg-color);
}
.wiki-editor blockquote p:last-child,
.wiki-content blockquote p:last-child {
  margin-bottom: 0 !important;
}
.wiki-editor b,
.wiki-editor strong,
.wiki-content b,
.wiki-content strong {
  color: #383838;
  font-weight: 600;
}
.wiki-editor h1,
.wiki-editor h2,
.wiki-editor h3,
.wiki-editor h4,
.wiki-editor h5,
.wiki-editor h6,
.wiki-content h1,
.wiki-content h2,
.wiki-content h3,
.wiki-content h4,
.wiki-content h5,
.wiki-content h6 {
  color: #171717;
}
.wiki-editor h2,
.wiki-editor h3,
.wiki-editor h4,
.wiki-editor h5,
.wiki-editor h6,
.wiki-content h2,
.wiki-content h3,
.wiki-content h4,
.wiki-content h5,
.wiki-content h6 {
  font-weight: 600;
}
.wiki-editor h1,
.wiki-content h1 {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 600;
}
.wiki-editor h1 + p,
.wiki-content h1 + p {
  margin-top: 1rem !important;
  line-height: 1.4;
}
.wiki-editor > p,
.wiki-content > p {
  margin-top: 1rem !important;
}
.wiki-editor h2,
.wiki-content h2 {
  font-size: 1.5rem;
  line-height: 1.56;
  margin: 3rem 0 1rem !important;
}
@media (min-width: 768px) {
  .wiki-editor h2,
  .wiki-content h2 {
    font-size: 1.6rem;
  }
}
.wiki-editor h3,
.wiki-content h3 {
  font-size: 1.25rem;
  line-height: 1.56;
  margin: 2rem 0 1rem !important;
}
@media (min-width: 768px) {
  .wiki-editor h3,
  .wiki-content h3 {
    font-size: 1.41rem;
  }
}
.wiki-editor h4,
.wiki-content h4 {
  font-size: 1.125rem;
  line-height: 1.56;
  margin-top: 1.25rem !important;
}
.wiki-editor h5:not(.modal-title),
.wiki-content h5:not(.modal-title) {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  margin-top: 1rem !important;
}
.wiki-editor h6,
.wiki-content h6 {
  font-size: 0.75rem;
  line-height: 1.35;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1rem !important;
}
.wiki-editor tr > td,
.wiki-editor tr > th,
.wiki-content tr > td,
.wiki-content tr > th {
  font-size: 0.875rem;
  padding: 0.5rem;
}
.wiki-editor th:empty,
.wiki-content th:empty {
  display: none;
}
.wiki-editor .screenshot,
.wiki-content .screenshot {
  border: 1px solid #c7c7c7;
  border-radius: 0.375rem;
  margin-top: 0.5rem !important;
}
.wiki-editor .screenshot + em,
.wiki-content .screenshot + em {
  text-align: center;
  display: block;
  margin-top: 0.5rem !important;
  margin-bottom: 2rem !important;
}
.wiki-editor p > code:not(.hljs),
.wiki-content p > code:not(.hljs) {
  padding: 0 0.25rem;
  background-color: var(--code-bg-color);
  color: var(--code-text-color);
  border-radius: 0.125rem;
}
.wiki-editor table,
.wiki-content table {
  border-color: #ededed;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.wiki-editor table td,
.wiki-editor table th,
.wiki-content table td,
.wiki-content table th {
  min-width: 1em;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
.wiki-editor table td > *,
.wiki-editor table th > *,
.wiki-content table td > *,
.wiki-content table th > * {
  margin-bottom: 0;
}
.wiki-editor table th,
.wiki-content table th {
  font-weight: bold;
  text-align: right;
  background-color: var(--active-item-color);
}
.wiki-editor table .selectedCell:after,
.wiki-content table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
.wiki-editor table .column-resize-handle,
.wiki-content table .column-resize-handle {
  position: absolute;
  left: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}
.wiki-editor table p,
.wiki-content table p {
  margin: 0;
}
.wiki-editor .table-bordered,
.wiki-editor .table-bordered th,
.wiki-editor .table-bordered td,
.wiki-content .table-bordered,
.wiki-content .table-bordered th,
.wiki-content .table-bordered td {
  border: 1px solid;
  border-color: #ededed;
  color: inherit;
}
.wiki-editor .table-bordered thead th,
.wiki-editor .table-bordered thead td,
.wiki-content .table-bordered thead th,
.wiki-content .table-bordered thead td {
  border-bottom-width: 1px;
}
.wiki-editor pre,
.wiki-content pre {
  margin: 1rem 0;
  background: #011627;
  color: #d6deeb;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
}
.wiki-editor pre:hover .copy-btn,
.wiki-content pre:hover .copy-btn {
  visibility: visible;
}
.wiki-editor pre .copy-btn,
.wiki-content pre .copy-btn {
  visibility: hidden;
  left: 0.5rem;
  padding: 0.75rem;
  position: absolute;
  background-color: inherit;
}
.wiki-editor pre .copy-btn:hover,
.wiki-content pre .copy-btn:hover {
  color: var(--gray-500);
  background-color: inherit;
  border-color: inherit;
}
.wiki-editor pre .copy-btn:focus,
.wiki-content pre .copy-btn:focus {
  box-shadow: none;
}
.wiki-editor pre code,
.wiki-content pre code {
  overflow-x: auto;
  color: inherit;
  background: none !important;
}
.wiki-editor pre code del.diffmod, .wiki-editor pre code del.diffdel,
.wiki-content pre code del.diffmod,
.wiki-content pre code del.diffdel {
  background-color: #8b0000;
}
.wiki-editor pre code ins.diffmod, .wiki-editor pre code ins.diffins,
.wiki-content pre code ins.diffmod,
.wiki-content pre code ins.diffins {
  background-color: #006400;
}
.wiki-editor hr,
.wiki-content hr {
  border-top: 1px solid #ededed;
}

.wiki-editor {
  margin-right: -2rem;
  width: 800px;
}
.wiki-editor .ace_print-margin {
  visibility: hidden !important;
}
@media (max-width: 991.98px) {
  .wiki-editor {
    margin: 0;
    width: 100%;
    max-width: none;
  }
}
.wiki-editor pre {
  padding: 0.75rem 1rem;
}
.wiki-editor h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.005em;
  text-align: right;
  margin-top: 0.75rem;
}
.wiki-editor h2 {
  margin-top: 2.5rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: right;
}
.wiki-editor h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.015em;
  text-align: right;
}
.wiki-editor p {
  font-size: 15px;
  font-weight: 420;
  line-height: 155%;
  letter-spacing: 0.02em;
  text-align: right;
}
.wiki-editor button:not(.btn-primary) {
  all: unset;
  cursor: pointer;
  background: var(--editor-background-color);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
}
.wiki-editor a.btn-primary-light {
  background-color: var(--btn-secondary-color) !important;
}
.wiki-editor button:hover {
  background-color: var(--editor-hover-button-color);
}
.wiki-editor .is-active {
  background-color: var(--editor-hover-button-color) !important;
}
.wiki-editor .dropdown {
  display: inline-block;
}
.wiki-editor .dropdown #dropdownMenuButton::after {
  display: none;
}
.wiki-editor .dropdown-menu.show {
  transform: translate3d(-1px, 28px, 0px) !important;
}
.wiki-editor .ace_editor {
  height: 70vh;
  padding: 0 1rem;
  padding-left: 120px;
  border-radius: 0.5rem;
  background-color: var(--editor-background-color);
}
@media (max-width: 991.98px) {
  .wiki-editor .ace_editor {
    padding-left: 1rem;
    width: 92vw;
  }
}
.wiki-editor .ace_editor .is-empty::before {
  content: attr(data-placeholder);
  float: right;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
.wiki-editor .ace_editor:focus-visible {
  outline: none;
}
.wiki-editor .ace_editor .ace_gutter {
  z-index: auto;
  background: var(--editor-background-color);
  color: var(--text-light);
}
.wiki-editor img.ProseMirror-selectednode {
  border: 2px solid #7cbcf5;
}
.wiki-editor > :last-child {
  overflow-x: auto;
  margin-top: 0 !important;
  border-radius: 0.5rem;
}
.wiki-editor > :first-child {
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 800px;
}
@media (max-width: 991.98px) {
  .wiki-editor > :first-child {
    width: 100%;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}
.wiki-editor > :first-child .wiki-edit-controls {
  flex-wrap: wrap;
  display: inline-flex;
  row-gap: 0.5rem;
}
.wiki-editor > :first-child .wiki-edit-control-btn {
  margin-right: auto;
  display: inline-flex;
}
.wiki-editor > :first-child .wiki-edit-control-btn > * {
  margin: 0.5rem 0.25rem;
}
@media (max-width: 991.98px) {
  .wiki-editor > :first-child .wiki-edit-control-btn {
    margin-right: 0;
    align-items: flex-start;
  }
}

.wiki-content {
  min-height: 55vh;
}
@media (max-width: 991.98px) {
  .wiki-content {
    max-width: calc(100vw - 3rem);
  }
}
.wiki-content div > p:first-child {
  margin-top: 1.5rem !important;
  line-height: 1.4;
}
.wiki-content code {
  padding: 0.75rem 1rem;
}
.wiki-content ul[data-type=taskList] p {
  margin: 0 !important;
}

.markdown-preview {
  overflow: auto;
  padding: 0 !important;
}

.btn:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn.btn-primary:hover {
  color: #fff;
  background-color: var(--editor-background-color);
  border-color: #383838;
}

ul[data-type=taskList] {
  list-style: none;
  padding: 0;
}
ul[data-type=taskList] p {
  margin: 0;
}
ul[data-type=taskList] li {
  display: flex;
}
ul[data-type=taskList] li > label {
  flex: 0 0 auto;
  margin-left: 0.25rem;
  margin-bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
ul[data-type=taskList] li > div {
  flex: 1 1 auto;
}
ul[data-type=taskList] li ul li,
ul[data-type=taskList] li ol li {
  display: list-item;
}
ul[data-type=taskList] li ul[data-type=taskList] > li {
  display: flex;
}
ul[data-type=taskList] li input {
  color: #000;
  margin: 0.1rem;
  margin-top: 0.4rem;
  border: 1px solid var(--gray-500);
  accent-color: black;
}
ul[data-type=taskList] li input:checked {
  background-color: var(--primary);
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>"), var(--checkbox-gradient);
  background-size: 57%, 100%;
  box-shadow: none;
  border: none;
}